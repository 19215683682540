<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/stores"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.storeContactDetails.title') }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button
            :router-link="
              `/tabs/stores/${$route.params.id}/contacts/${$route.params.contactId}/edit`
            "
          >
            <ion-icon slot="icon-only" :icon="icons.pencilOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="odn-contact">
        <div class="odn-contact-name">
          {{ contact.lastname }}
          {{ contact.firstname }}
        </div>
        <div v-if="contact.sector" class="odn-contact-sector">
          <ion-icon :icon="icons.scanOutline" class="ion-margin-end" />
          {{ contact.sector.name }}
        </div>
        <div v-if="contact.phone" class="odn-contact-phone">
          <ion-icon :icon="icons.callOutline" class="ion-margin-end" />
          <a :href="`callto:${contact.phone}`">
            {{ contact.phone }}
          </a>
        </div>
        <div v-if="contact.email" class="odn-contact-email">
          <ion-icon :icon="icons.mailOutline" class="ion-margin-end" />
          <a :href="`mailto:${contact.email}`">
            {{ contact.email }}
          </a>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  toastController,
} from '@ionic/vue';
import {
  pencilOutline,
  mailOutline,
  callOutline,
  scanOutline,
} from 'ionicons/icons';

import APIService from '@s/api.service';

export default {
  name: 'StoreContactDetails',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
  },
  data() {
    return {
      contact: {},
      loading: true,
      icons: {
        pencilOutline,
        mailOutline,
        callOutline,
        scanOutline,
      },
    };
  },
  ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.loading = true;
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const response = await APIService.get(
          `/contacts/${this.$route.params.contactId}`
        );
        this.contact = response.data;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.contact.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.odn-contact {
  padding: 16px;

  &-name {
    font-size: 20px;
  }

  &-sector,
  &-email,
  &-phone {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    font-size: 16px;

    > ion-icon {
      font-size: 30px;
    }

    > a {
      display: inline-block;
      color: var(--ion-color-secondary);
      text-decoration: none;
    }
  }

  &-notes {
    margin-top: 16px;
  }
}
</style>
